<template>
  <div class="row">
    <el-dialog
      :title="form.id_valid === null ? '事件建檔' : '單日建檔'"
      :visible.sync="visible"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="使用者 ID">
          <el-input
            v-model="form.user_id"
            :disabled="dialogStatus === 'add' ? false : true"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="form.name"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>

        <el-form-item label="群組">
          <el-checkbox
            :indeterminate="isGroupIndeterminate"
            v-model="groupCheckAll"
            @change="handleGroupCheckAllChange"
            :disabled="dialogStatus === 'info' ? true : false"
            >全選</el-checkbox
          >
          <div style="margin: 15px 0"></div>
          <el-checkbox-group
            v-model="form.group"
            @change="handleCheckedGroupChange"
            :disabled="dialogStatus === 'info' ? true : false"
          >
            <el-checkbox v-for="gp in group" :label="gp" :key="gp">{{
              gp
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="建檔裝置">
          <el-checkbox
            :indeterminate="isDeviceIndeterminate"
            v-model="deviceCheckAll"
            @change="handleDeviceCheckAllChange"
            :disabled="dialogStatus === 'info' ? true : false"
            >全選</el-checkbox
          >
          <div style="margin: 15px 0"></div>
          <el-checkbox-group
            v-model="form.devices"
            @change="handleCheckedDeviceChange"
            :disabled="dialogStatus === 'info' ? true : false"
          >
            <el-checkbox
              v-for="device in devices"
              :label="device.sn"
              :key="device.sn"
              >{{ device.sn }} ({{ device.name }})</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="電話" prop="phone">
          <el-input
            v-model="form.phone"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item label="IC" prop="ic">
          <el-input
            v-model="form.ic"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>

        <el-form-item label="人員有效期" prop="id_valid">
          <el-date-picker
            v-model="form.id_valid"
            type="date"
            placeholder="選擇日期"
            :picker-options="pickerOptions"
            :disabled="dialogStatus === 'info' ? true : false"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="可通行時間"
          ><el-button @click="addCycle" v-if="dialogStatus !== 'info'"
            >新增可通行時間</el-button
          ></el-form-item
        >
        <el-form-item
          v-for="(cycle, index) in form.valid_cycle"
          :label="'時間'"
          :key="index"
          :prop="'valid_cycle.' + index + '.start_time'"
          :rules="{
            required: true,
            message: '可通行時間不能為空',
            trigger: 'blur',
          }"
        >
          <el-time-select
            placeholder="開始時間"
            v-model="cycle.start_time"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '24:00',
            }"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-time-select>
          -
          <el-time-select
            placeholder="結束時間"
            v-model="cycle.end_time"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '24:00',
            }"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-time-select>
          <el-button
            @click.prevent="removeCycle(cycle)"
            v-if="dialogStatus !== 'info'"
            >删除</el-button
          >
        </el-form-item>

        <el-form-item label="照片" prop="face_image">
          <el-upload
            action=""
            accept=".jpg, .jpeg"
            :show-file-list="false"
            :on-change="imageChange"
            :auto-upload="false"
            :disabled="dialogStatus === 'info' ? true : false"
          >
            <img
              v-if="form.face_image"
              :src="form.face_image"
              class="face-image"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('form')"
          >確 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import swal from "sweetalert2";
import {
  Dialog,
  Button,
  Form,
  FormItem,
  Input,
  Checkbox,
  CheckboxGroup,
  Upload,
  TimeSelect,
  DatePicker,
  Col,
} from "element-ui";
import { Card } from "src/components/UIComponents";
import axios from "axios";
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Upload);
Vue.use(TimeSelect);
Vue.use(DatePicker);
Vue.use(Col);

export default {
  components: {
    Card,
  },
  name: "stranger-dialog",
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
    currentData: {
      type: Object,
      require: true,
      default: {},
    },
    dialogStatus: {
      type: String,
      require: true,
      defalut: "info",
    },
  },
  data() {
    return {
      form: {
        devices: [],
        group: [],
      },
      rules: {
        name: [{ required: true, message: "請輸入姓名", trigger: "blur" }],
      },
      devices: [],
      group: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "明天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一週後",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      deviceCheckAll: false,
      groupCheckAll: false,
      isDeviceIndeterminate: false,
      isGroupIndeterminate: false,
    };
  },
  created() {
    this.form = { ...this.currentData };
    this.getDevices();
    this.getGroup();
    console.log(this.form);
  },
  methods: {
    getDevices() {
      axios.get(`/face/device`).then((res) => {
        const devicesSn = res.data.rows.map((item, index, array) => {
          return {
            sn: item.sn,
            name: item.name,
          };
        });
        if (this.dialogStatus === "add") {
          this.deviceCheckAll = true;
          this.form.devices = res.data.rows.map((item, index, array) => {
            return item.sn;
          });
        }
        this.devices = devicesSn;
      });
    },
    getGroup() {
      axios.get(`/face/group`).then((res) => {
        console.log(res.data);
        const group = res.data.rows.map((item, index, array) => {
          return item.name;
        });
        this.group = group;
        console.log(this.group);
      });
    },
    handleDeviceCheckAllChange(val) {
      const allDevices = this.devices.map((item, index, array) => {
        return item.sn;
      });
      this.form.devices = val ? allDevices : [];
      this.isDeviceIndeterminate = false;
    },
    handleCheckedDeviceChange(value) {
      let checkedCount = value.length;
      this.deviceCheckAll = checkedCount === this.devices.length;
      this.isDeviceIndeterminate =
        checkedCount > 0 && checkedCount < this.devices.length;
    },

    handleGroupCheckAllChange(val) {
      this.form.group = val ? this.group : [];
      this.isGroupIndeterminate = false;
    },
    handleCheckedGroupChange(value) {
      let checkedCount = value.length;
      this.groupCheckAll = checkedCount === this.group.length;
      this.isGroupIndeterminate =
        checkedCount > 0 && checkedCount < this.group.length;
    },

    removeCycle(item) {
      var index = this.form.valid_cycle.indexOf(item);
      if (index !== -1) {
        this.form.valid_cycle.splice(index, 1);
      }
    },
    addCycle() {
      this.form.valid_cycle.push({
        start_time: "",
        end_time: "",
      });
    },

    imageChange(file) {
      this.getBase64(file.raw).then((res) => {
        this.form.face_image = res;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let img = "";
        reader.readAsDataURL(file);
        reader.onload = () => {
          img = reader.result;
        };
        reader.onerror = (err) => {
          reject(err);
        };
        reader.onloadend = () => {
          resolve(img);
        };
      });
    },
    handleClose() {
      this.$emit("close-dialog", { visible: !this.visible });
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid);
        if (valid) {
          if (this.dialogStatus === "add") {
            axios
              .post(`/face/user`, {
                sn: this.form.devices,
                data: this.form,
              })
              .then(
                (res) => {
                  this.$notify({
                    title: "新增建檔成功",
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                  swal.fire("新增建檔成功!", "", "success");
                  this.timeOutReload = setTimeout(() => {
                    this.getData();
                  }, 500);
                },
                (res) => {
                  this.$notify({
                    title: "新增建檔失敗",
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                  swal.fire("新增建檔失敗", "", "error");
                }
              );
          }

          this.$emit("close-dialog", {
            visible: !this.visible,
            data: this.form,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
