<template>
  <div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">裝置設定</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12 mt-2">
          <el-table
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :formatter="column.formatter"
            >
            </el-table-column>

            <el-table-column
              :min-width="130"
              fixed="right"
              class-name="td-actions"
              label="功能"
            >
              <template slot-scope="props">
                <p-button
                  type="info"
                  size="sm"
                  icon
                  title="裝置資訊"
                  @click="handleInfo(props.$index, props.row)"
                >
                  <i class="fa fa-user"></i>
                </p-button>
                <p-button
                  type="success"
                  size="sm"
                  icon
                  title="裝置編輯"
                  @click="handleEdit(props.$index, props.row)"
                >
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button
                  type="danger"
                  size="sm"
                  icon
                  title="裝置刪除"
                  @click="handleDelete(props.$index, props.row)"
                >
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <div class="col-sm-6">
          <p-pagination
            class="pull-right"
            v-model="pagination.currentPage"
            @change-page="handleCurrentChange"
            :per-page="pagination.perPage"
            @per-page="handleSizeChange"
            :total="pagination.total"
          >
          </p-pagination>
        </div>
      </div>
    </div>

    <div v-if="dialogVisible">
      <settingDeviceDialog
        :visible="dialogVisible"
        @close-dialog="handleCloseDialog"
        :current-data="currentData"
        :dialog-status="dialogStatus"
      />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { nextTick } from "vue";
import swal from "sweetalert2";
import moment from "moment";
import { Table, TableColumn, Select, Option } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import axios from "axios";
import settingDeviceDialog from "./SettingDeviceDialog.vue";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
export default {
  components: {
    PPagination,
    settingDeviceDialog,
  },
  name: "setting-env",
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      tableColumns: [
        {
          prop: "name",
          label: "裝置名稱",
          minWidth: 50,
        },
        {
          prop: "sn",
          label: "裝置序號",
          minWidth: 50,
        },
        {
          prop: "version_name",
          label: "版本名稱",
          minWidth: 45,
        },
        {
          prop: "total",
          label: "裝置人數",
          minWidth: 45,
        },
        {
          prop: "group",
          label: "群組",
          minWidth: 45,
        },
        {
          prop: "updatedAt",
          label: "更新時間",
          minWidth: 70,
          formatter(row, column, cellValue, index) {
            return moment(cellValue).local().format("YYYY-MM-DD HH:mm:ss");
          },
        },
      ],
      tableData: [],
      dialogVisible: false,
      currentData: {},
      dialogStatus: "info",
    };
  },
  async mounted() {
    this.$auth.refresh();
    await this.getData();
  },
  methods: {
    async getData() {
      const limit = this.pagination.perPage;
      const index = this.pagination.currentPage;

      try {
        const res = await this.$http.get(
          `/face/device?limit=${limit}&index=${index}`
        );

        this.tableData = res.data.rows;
        this.pagination.total = res.data.count;
        this.tableData = this.tableData.map((v) => ({
          ...v,
          total: "取得數量失敗",
        }));

        for (
          let deviceIndex = 0;
          deviceIndex < this.tableData.length;
          deviceIndex++
        ) {
          const device = this.tableData[deviceIndex];
          const getUserInfo = await this.getUserInfo(device.sn);
          const { total } = getUserInfo;
          this.tableData[deviceIndex].total = total;
        }
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async getUserInfo(sn) {
      try {
        const res = await this.$http.get(`/face/getUserInfo?sn=${sn}&value=0`);
        const getUserInfo = res.data;
        return getUserInfo.data;
      } catch (e) {
        console.log(e);
        return { total: "取得數量失敗" };
      }
    },
    handleSizeChange(size) {
      this.pagination.perPage = size;
      this.getData();
    },
    handleCurrentChange(currentPage) {
      this.pagination.currentPage = currentPage;
      this.getData();
    },
    handleInfo(index, row) {
      this.currentData = row;
      this.dialogVisible = true;
      this.dialogStatus = "info";
    },
    handleEdit(index, row) {
      this.currentData = row;
      this.dialogVisible = true;
      this.dialogStatus = "edit";
      // alert(`Your want to edit ${row.name}`);
    },
    handleCloseDialog({ visible }) {
      // this.data.find({ user_id } => user_id === data.user_id)
      this.dialogVisible = visible;
      this.timeOutReload = setTimeout(() => {
        this.getData();
      }, 500);
    },
    handleDelete(index, row) {
      swal
        .fire({
          title: "是否移除",
          showDenyButton: true,
          confirmButtonText: "是",
          denyButtonText: "否",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios.delete(`/face/device?sn=${row.sn}`).then(
              (res) => {
                this.$notify({
                  title: "移除裝置成功",
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
                swal.fire("移除裝置成功!", "", "success");
                this.timeOutReload = setTimeout(() => {
                  this.getData();
                }, 500);
              },
              (res) => {
                this.$notify({
                  title: "移除裝置失敗",
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
                swal.fire("移除裝置失敗", "", "error");
              }
            );
          } else if (result.isDenied) {
            swal.fire("裝置尚未移除", "", "info");
          }
        });
    },
  },
};
</script>
<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
