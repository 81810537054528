<template>
  <div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6" v-for="stats in deviceCards">
        <stats-card
          :type="stats.type"
          :icon="stats.icon"
          :small-title="stats.title"
          :title="stats.value"
        >
          <div class="stats" slot="footer">
            <i :class="stats.footerIcon"></i>
            {{ stats.footerText }}
          </div>
          <div class="stats" slot="footer">
            <p-button
              type="default"
              link
              @click="setDoor(stats.sn)"
              v-if="stats.sn"
              >{{ $t("dashboard.remoteDoor") }}</p-button
            >
            <p-button
              type="default"
              link
              @click="setOpenDoorPassword(stats.sn)"
              v-if="stats.sn"
              >{{ $t("dashboard.remotePassword") }}</p-button
            >
          </div>
        </stats-card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-sm-6">
        <circle-chart-card
          :key="system.cpu.avg"
          :percentage="system.cpu.avg"
          title="CPU"
          :description="$t('dashboard.cpuUsage')"
          color="#4acccd"
        >
          <template slot="footer">
            <div class="legend">
              <i class="fa fa-circle text-info"></i> {{ $t("dashboard.usage") }}
            </div>
          </template>
        </circle-chart-card>
      </div>

      <div class="col-lg-3 col-sm-6">
        <circle-chart-card
          :key="system.mem.percentage"
          :percentage="system.mem.percentage"
          title="Memory"
          :description="$t('dashboard.memoryUsage')"
          color="#4acccd"
        >
          <template slot="footer">
            <div class="legend">
              <i class="fa fa-circle text-info"></i> {{ $t("dashboard.usage") }}
            </div>
          </template>
        </circle-chart-card>
      </div>
    </div>
  </div>
</template>
<script>
import CircleChartCard from "src/components/UIComponents/Cards/CircleChartCard.vue";
import StatsCard from "src/components/UIComponents/Cards/StatsCard";
import PPagination from "src/components/UIComponents/Pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
    StatsCard,
    CircleChartCard,
    PPagination,
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      deviceCards: [],
      system: {
        cpu: {
          avg: 0,
        },
        mem: {
          percentage: 0,
        },
        disk: [],
      },
    };
  },
  created() {
    this.getDeviceOnline();
    this.getSystemStatus();
    this.$watch("$i18n.locale", this.getDeviceOnline); // 監聽語言變化
    this.$watch("$i18n.locale", this.getSystemStatus); // 監聽語言變化
  },
  methods: {
    async getDeviceOnline() {
      const deviceRes = await axios.get(`/face/device`);
      const deviceOnlineRes = await axios.get(`/face/device/online`);

      const devicesSn = deviceRes.data.rows.map((item, index, array) => {
        if (deviceOnlineRes.data.indexOf(item.sn) !== -1) {
          return {
            type: "success",
            icon: "nc-icon nc-sound-wave",
            title: this.$t("dashboard.deviceStatus"),
            value: this.$t("dashboard.online"),
            sn: item.sn,
            footerText: `${item.sn} (${item.name})`,
            footerIcon: "nc-icon nc-tablet-2",
          };
        }
        return {
          type: "danger",
          icon: "nc-icon nc-sound-wave",
          title: this.$t("dashboard.deviceStatus"),
          value: this.$t("dashboard.offline"),
          sn: "",
          footerText: `${item.sn} (${item.name})`,
          footerIcon: "nc-icon nc-tablet-2",
        };
      });
      this.deviceCards = devicesSn;
    },
    async getSystemStatus() {
      const res = await axios.get(`/system/status`);
      this.system = res.data;
    },
    async setDoor(sn) {
      if (sn !== "") {
        axios
          .post(`/face/setDoor`, {
            sn,
            value: "on",
          })
          .then(
            (res) => {
              this.$notify({
                title: this.$t("dashboard.doorOpenSuccess"),
                message: res.data.msg,
                verticalAlign: "bottom",
                icon: "nc-icon nc-check-2",
                type: "success",
              });
            },
            (res) => {
              this.$notify({
                title: this.$t("dashboard.doorOpenFailure"),
                message: res.response.data.msg,
                verticalAlign: "bottom",
                icon: "nc-icon nc-alert-circle-i",
                type: "danger",
              });
            }
          );
      }
    },
    async setOpenDoorPassword(sn) {
      if (sn !== "") {
        Swal.fire({
          title: this.$t("dashboard.enterRemoteDoorPassword"),
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: this.$t("dashboard.confirm"),
          cancelButtonText: this.$t("dashboard.cancel"),
          showLoaderOnConfirm: true,
          preConfirm: (login) => {
            return axios
              .post(`/face/setOpenDoorPassword`, { sn, value: login })
              .then(
                (res) => {
                  this.$notify({
                    title: this.$t("dashboard.remoteDoorPasswordSetSuccess"),
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                  return res.data.msg;
                },
                (res) => {
                  this.$notify({
                    title: this.$t("dashboard.remoteDoorPasswordSetFailure"),
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                }
              )
              .catch((error) => {
                Swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      }
    },
  },
};
</script>
<style></style>
