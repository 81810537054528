import { render, staticRenderFns } from "./StrangerDialog.vue?vue&type=template&id=62b9cf86&scoped=true&"
import script from "./StrangerDialog.vue?vue&type=script&lang=js&"
export * from "./StrangerDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62b9cf86",
  null
  
)

export default component.exports