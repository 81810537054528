<template>
  <div class="row">
    <el-dialog
      title="參數設定"
      :visible.sync="visible"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="名稱">
          <el-input
            v-model="form.machine_name"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item label="群組" prop="machine_group">
          <el-input
            v-model="form.machine_group"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item label="種類" prop="machine_type">
          <el-input
            v-model="form.machine_type"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item label="latitude" prop="latitude">
          <el-input
            v-model="form.latitude"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item label="longitude" prop="longitude">
          <el-input
            v-model="form.longitude"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item label="角度" prop="machine_angle">
          <el-input
            v-model="form.machine_angle"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item label="URL" prop="machine_url">
          <el-input
            v-model="form.machine_url"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('form')"
          >確 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { Dialog, Button, Form, FormItem, Input } from "element-ui";
import axios from "axios";
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);

export default {
  components: {},
  name: "setting-machine-dialog",
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
    currentData: {
      type: Object,
      require: true,
      default: {},
    },
    dialogStatus: {
      type: String,
      require: true,
      defalut: "info",
    },
  },
  data() {
    return {
      form: {},
      rules: {},
    };
  },
  created() {
    this.form = { ...this.currentData };
    console.log(this.form);
  },
  methods: {
    handleClose() {
      this.$emit("close-dialog", { visible: !this.visible });
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogStatus === "add") {
            axios
              .post(`/machine/env`, {
                machine_name: this.form.machine_name,
                machine_group: this.form.machine_group,
                machine_type: this.form.machine_type,
                latitude: this.form.latitude,
                longitude: this.form.longitude,
                machine_angle: this.form.machine_angle,
                machine_url: this.form.machine_url,
              })
              .then(
                (res) => {
                  this.$notify({
                    title: "新增參數成功",
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                },
                (res) => {
                  this.$notify({
                    title: "新增參數失敗",
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                }
              );
          }
          if (this.dialogStatus === "edit") {
            axios
              .put(`/machine/env`, {
                id: this.form.id,
                machine_name: this.form.machine_name,
                machine_group: this.form.machine_group,
                machine_type: this.form.machine_type,
                latitude: this.form.latitude,
                longitude: this.form.longitude,
                machine_angle: this.form.machine_angle,
                machine_url: this.form.machine_url,
              })
              .then(
                (res) => {
                  this.$notify({
                    title: "更新參數成功",
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                },
                (res) => {
                  this.$notify({
                    title: "更新參數失敗",
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                }
              );
          }

          this.$emit("close-dialog", {
            visible: !this.visible,
            data: this.form,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
