<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">工時紀錄功能</h4>
      <card>
        <!-- <h4 slot="header" class="card-title"></h4> -->

        <el-row type="flex" :gutter="20">
          <el-col :span="19">
            <div class="grid-content bg-purple">
              <el-button
                style="margin-left: 10px"
                size="small"
                type="success"
                @click="exportWorkTimeFile"
                >匯出</el-button
              >
            </div>
          </el-col>
        </el-row>
      </card>
      <!-- end card -->
    </div>
    <div class="col-md-12">
      <h4 class="title">工時紀錄</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-10 mt-2">
          <el-select
            class="select-default"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-2 mt-2">
          <div class="pull-right">
            <el-date-picker
              :localTime="true"
              v-model="date"
              type="daterange"
              range-separator="~"
              :start-placeholder="$t('workTimeLogTables.daterangeStart')"
              :end-placeholder="$t('workTimeLogTables.daterangeEnd')"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="col-sm-2 mt-4">
          <div class="pull-right">
            <fg-input
              class="input-sm"
              placeholder="使用者 ID"
              v-model="searchQueryUserId"
              addon-right-icon="nc-icon nc-zoom-split"
            >
            </fg-input>
          </div>
        </div>
        <div class="col-sm-2 mt-4">
          <div class="pull-right">
            <fg-input
              class="input-sm"
              placeholder="使用者姓名"
              v-model="searchQueryUserName"
              addon-right-icon="nc-icon nc-zoom-split"
            >
            </fg-input>
          </div>
        </div>
        <div class="col-sm-2 mt-4">
          <div class="pull-right">
            <fg-input
              class="input-sm"
              placeholder="上班裝置"
              v-model="searchQueryUpDevice"
              addon-right-icon="nc-icon nc-zoom-split"
            >
            </fg-input>
          </div>
        </div>
        <div class="col-sm-2 mt-4">
          <div class="pull-right">
            <fg-input
              class="input-sm"
              placeholder="下班裝置"
              v-model="searchQueryDownDevice"
              addon-right-icon="nc-icon nc-zoom-split"
            >
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            v-loading="loading"
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <div class="col-sm-6">
          <p-pagination
            class="pull-right"
            v-model="pagination.currentPage"
            @change-page="handleCurrentChange"
            :per-page="pagination.perPage"
            @per-page="handleSizeChange"
            :total="pagination.total"
          >
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import swal from "sweetalert2";
import moment from "moment";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Loading,
  Button,
  DatePicker,
  Row,
  Col,
} from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import axios from "axios";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Loading);
Vue.use(Button);
Vue.use(DatePicker);
Vue.use(Row);
Vue.use(Col);
export default {
  components: {
    PPagination,
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQueryUserId: "",
      searchQueryUserName: "",
      searchQueryUpDevice: "",
      searchQueryDownDevice: "",
      date: [],
      tableColumns: [
        {
          prop: "id",
          label: "序號",
        },
        {
          prop: "user_id",
          label: "使用者 ID",
        },
        {
          prop: "user_name",
          label: "使用者姓名",
        },
        {
          prop: "user_group",
          label: "使用者群組",
        },
        {
          prop: "up_time",
          label: "上班時間",
        },
        {
          prop: "up_device",
          label: "上班裝置",
        },
        {
          prop: "down_time",
          label: "下班時間",
        },
        {
          prop: "down_device",
          label: "下班裝置",
        },
      ],
      tableData: [],
      loading: true,
    };
  },
  watch: {
    searchQueryUserId: function () {
      this.getData();
    },
    searchQueryUserName: function () {
      this.getData();
    },
    searchQueryUpDevice: function () {
      this.getData();
    },
    searchQuery: function () {
      this.getData();
    },
    searchQueryDownDevice: function () {
      this.getData();
    },
    date: function () {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const params = {};
      params.limit = this.pagination.perPage;
      params.index = this.pagination.currentPage;
      params.userId = this.searchQueryUserId ? this.searchQueryUserId : undefined;
      params.userName = this.searchQueryUserName ? this.searchQueryUserName : undefined;
      params.upDevice = this.searchQueryUpDevice ? this.searchQueryUpDevice : undefined;
      params.downDevice = this.searchQueryDownDevice ? this.searchQueryDownDevice : undefined;
      if (this.date) {
        params.date = this.date.length !== 0 ? this.date : undefined;
      }

      this.loading = true;
      axios.get(`/work-attendance/worktime`, { params }).then((res) => {
        this.tableData = res.data.rows;
        this.pagination.total = res.data.count;
        this.loading = false;
      });
    },
    handleSizeChange(size) {
      this.pagination.perPage = size;
      this.getData();
    },
    handleCurrentChange(currentPage) {
      this.pagination.currentPage = currentPage;
      this.getData();
    },
    handleSearchQuery() {
      this.getData();
    },
    async exportWorkTimeFile() {
      const params = {};
      if (this.date) {
        params.date = this.date.length !== 0 ? this.date : undefined;
      }
      params.encode = "big5";
      swal.fire("匯出出勤紀錄檔案 請稍候 ...", "", "success");
      let fileData = await axios({
        url: "/work-attendance/worktime/export",
        responseType: "blob",
        params,
      });
      let URL = window.URL || window.webkitURL;
      let downloadUrl = URL.createObjectURL(fileData.data);
      let a = document.createElement("a");
      a.download = "匯出出勤紀錄檔案.zip";
      a.href = downloadUrl;
      a.click();
      a = null;
      downloadUrl && URL.revokeObjectURL(downloadUrl);
    },
  },
};
</script>
<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
