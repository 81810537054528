<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">系統設定</h4>
    </div>

    <settingGroup />
    <settingDevice />
    <settingEnv />
    <settingAdMachine v-if="showSettingAdMachine" />
    <settingMachine v-if="showSettingMachine"/>
  </div>
</template>
<script>
import Vue from "vue";
import settingGroup from "./SettingGroupTable.vue";
import settingDevice from "./SettingDeviceTable.vue";
import settingEnv from "./SettingEnvTable.vue";
import settingAdMachine from "./SettingAdMachineTable.vue";
import settingMachine from "./SettingMachineTable.vue";
export default {
  components: {
    settingGroup,
    settingDevice,
    settingEnv,
    settingAdMachine,
    settingMachine,
  },
  computed: {
    showSettingAdMachine() {
      console.log(process.env.SHOW_SETTING_AD_MACHINE);
      return process.env.SHOW_SETTING_AD_MACHINE === "true";
    },
    showSettingMachine() {
      console.log(process.env.SHOW_SETTING_MACHINE);
      return process.env.SHOW_SETTING_MACHINE === "true";
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
