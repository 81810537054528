<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">匯出使用者照片</h4>
      <card>
        <!-- <h4 slot="header" class="card-title"></h4> -->

        <el-row type="flex" :gutter="20">
          <el-col>
            <div class="grid-content bg-purple">
              <el-button
                style="margin-left: 10px"
                size="small"
                type="success"
                @click="exportUserFile"
                >匯出使用者</el-button
              >
            </div>
          </el-col>
        </el-row>
      </card>
      <!-- end card -->
    </div>
    <!--  end col-md-6  -->
    <div class="col-md-12">
      <h4 class="title">匯入使用者照片</h4>
      <card>
        <!-- <h4 slot="header" class="card-title"></h4> -->
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item label="匯入裝置" prop="devices">
            <el-checkbox
              :indeterminate="isDeviceIndeterminate"
              v-model="deviceCheckAll"
              @change="handleDeviceCheckAllChange"
              >全選</el-checkbox
            >
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="ruleForm.devices"
              @change="handleCheckedDeviceChange"
            >
              <el-checkbox
                v-for="device in devices"
                :label="device.sn"
                :key="device.sn"
                >{{ device.sn }} ({{ device.name }})</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="匯入檔案" prop="import_file">
            <el-upload
              accept=".zip"
              ref="upload"
              name="import_file"
              action=""
              :file-list="fileList"
              :limit="1"
              :on-exceed="handleExceed"
              :on-change="handleUploadChange"
              :before-upload="handleBeforeUpload"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :auto-upload="false"
            >
              <el-button slot="trigger" size="small" type="primary"
                >選取已打包 .zip 壓縮檔</el-button
              >
              <el-button
                style="margin-left: 10px"
                size="small"
                type="success"
                @click="submitUpload('ruleForm')"
                >上傳已打包壓縮檔匯入伺服器</el-button
              >
              <div slot="tip" class="el-upload__tip">
                請先將要匯入的照片，使用照片檔名格式重新命名後加入 zip
                壓縮檔上傳，即完成匯入。
              </div>
              <div slot="tip" class="el-upload__tip">
                照片檔案格式: (ID)_(姓名)_(ic 編號).jpg
              </div>
              <div slot="tip" class="el-upload__tip">
                如不使用 ic 卡請使用 x 取代如: 001_小林_x.jpg
              </div>
              <div slot="tip" class="el-upload__tip">僅支援 zip 壓縮檔上傳</div>
              <el-progress :percentage="progressPercent"></el-progress>
            </el-upload>
          </el-form-item>
        </el-form>
      </card>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import swal from "sweetalert2";
import {
  Upload,
  Button,
  Checkbox,
  CheckboxGroup,
  Form,
  FormItem,
  Progress,
  Row,
  Col,
} from "element-ui";
import { Card } from "src/components/UIComponents";
import axios from "axios";
Vue.use(Upload);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Progress);
Vue.use(Row);
Vue.use(Col);
export default {
  components: {
    Card,
  },
  data() {
    return {
      ruleForm: {
        devices: [],
      },
      fileList: [],
      progressPercent: 0,
      rules: {
        devices: [
          { required: true, message: "請至少選擇 1 個裝置", trigger: "blur" },
        ],
      },
      devices: [],
      deviceCheckAll: false,
      isDeviceIndeterminate: false,
    };
  },
  created() {
    this.getDevices();
  },
  methods: {
    getDevices() {
      axios.get(`/face/device`).then((res) => {
        const devicesSn = res.data.rows.map((item, index, array) => {
          return {
            sn: item.sn,
            name: item.name,
          };
          // return item.sn;
        });
        this.devices = devicesSn;
      });
    },
    handleDeviceCheckAllChange(val) {
      const allDevices = this.devices.map((item, index, array) => {
        return item.sn;
      });
      this.ruleForm.devices = val ? allDevices : [];
      this.isDeviceIndeterminate = false;
    },
    handleCheckedDeviceChange(value) {
      let checkedCount = value.length;
      this.deviceCheckAll = checkedCount === this.devices.length;
      this.isDeviceIndeterminate =
        checkedCount > 0 && checkedCount < this.devices.length;
    },
    async exportUserFile() {
      swal.fire("匯出使用者檔案 請稍候 ...", "", "success");
      let fileData = await axios({
        url: "/face/user/export",
        responseType: "blob",
      });
      let URL = window.URL || window.webkitURL;
      let downloadUrl = URL.createObjectURL(fileData.data);
      let a = document.createElement("a");
      a.download = "匯出使用者檔案.zip";
      a.href = downloadUrl;
      a.click();
      a = null;
      downloadUrl && URL.revokeObjectURL(downloadUrl);
    },

    handleUploadChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    handleBeforeUpload(file) {
      this.fileList.pop(file);
    },
    submitUpload(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          this.fileList.forEach(function (file) {
            fd.append("import_file", file.raw, file.name);
          });
          const query = this.ruleForm.devices.map((n) => `sn=${n}`).join("&");
          axios
            .post(`/face/user/import?${query}`, fd, {
              onUploadProgress: (progressEvent) => {
                this.progressPercent = Number(
                  ((progressEvent.loaded / progressEvent.total) * 100).toFixed(
                    2
                  )
                );
              },
            })
            .then(
              (res) => {
                this.$notify({
                  title: "匯入成功",
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
              },
              (res) => {
                this.$notify({
                  title: "匯入失敗",
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
              }
            );
        } else {
          return false;
        }
      });
    },
    handleExceed(files, fileList) {
      alert("請移除已上傳檔案");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>
<style></style>
